<template>
    <router-view style="height: 100%"></router-view>
</template>

<script>
export default {
  name: "localeRouterView",
  props : ['lang'],
  computed : {
    token() {
      return this.$i18n.availableLocales
    }
  },
  beforeMount() {
    this.$store.state.routeLang = this.lang
    if (this.lang){
      this.LOCALE_CHANGE(this.lang)
    }
  }
}
</script>

<style scoped>

</style>